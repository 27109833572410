import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import less from 'less'
import axios from 'axios'
Vue.prototype.$axios = axios;
import 'jquery'
import Print from 'vue-print-nb'
Vue.use(Print);
Vue.use(less)
Vue.use(ElementUI);
Vue.config.productionTip = false
import downloadPDF from './assets/static/js/htmlToPdf'
// 使用Vue.use()方法就会调用工具方法中的install方法
Vue.prototype.$downloadPDF = downloadPDF;
// Vue.prototype.$myfile = myfile;
// 地图
export default new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')